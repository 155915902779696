// Types
type IWhitelist = {
  whitelist: Record<string, number>;
};

// Config from generator
export const presaleConfig: IWhitelist = {
  whitelist: {
    "0x886478D3cf9581B624CB35b5446693Fc8A58B787": 2,
    "0x53c91f33e4dA805d04DCe861C536fA1674e7334d": 2,
    "0xa5d981BC0Bc57500ffEDb2674c597F14a3Cb68c1": 2,
    "0x8591420A904b7e0E1196B000E0CC6e08900dB0A9": 2,
    "0x3Af7D7664CA02bb937B6C338F37065becd3ed0a2": 2,
    "0x98932660390DD949cbD5819F85fc026112d130F3": 2,
    "0x7bD0492CF724A7eE158C967AacC95bB41fB00B0e": 2,
    "0xD3fdC98D610349f5C94D9C341D878403721C7D34": 1,
    "0x0EA9f5D0C97b430D5426465e2832A504Bd6Dd9F9": 1,
    "0x46c3adC31f3c8B8ee11B6468B7c31A21A2564611": 1,
    "0x949f7b32EA16870b61E53D852BCDA9E033B6e6a2": 1,
    "0x3B0B262b187001522c34EDcaFc378500133aB230": 1,
    "0x06d12a7C173de6b9e7eafaa97Be609d9667AE1D2": 1,
    "0x8940e70Bb7e9230960691118B70B2Ca3187383ef": 1,
    "0x48f0EfE12A93D43FbF81D1Fff7C22B4B0A06661e": 1,
    "0x62239c6Bd68FC55478C2Fa0E1eDd626E5810cA07": 1,
    "0xA6b4E4323903c5B024809eE839641A298DFbea82": 1,
    "0x0f67D297A99E66277d39549E2ca9BE118CF42Cf1": 1,
    "0x1A7861a39E74D93c8EB883d774f168BE46f81228": 1,
    "0xEeeE61D1A5df13e4a0B0aFBc65B0D91A6B7015E6": 1,
    "0x155F541ED89e19a2275e3805900d06BC956e29aa": 1,
    "0x55BC8f5B007b29c5AC09C01796879987F6B55F26": 1,
    "0xe193ABDad96B39532f66c34e758AE945A3288e5F": 1,
    "0x616356306A7A89Bc470508Cef96B62F7FAdaf91E": 1,
    "0x0B9580C35DB43c1df34096795c7434DCa2069e9c": 1,
    "0x717BB98c2DE5d080F248fB12a73B5011B808d7B1": 1,
    "0xbde5c6347f9c08179c4D81Cd99506504D86a3731": 1,
    "0x594dE8f261F6B3bcABB79C03fb92010C7dfbB131": 1,
    "0x89Ab3525dD9d3A60f4ae5FC5f2A633401C8f8a30": 1,
    "0x083cf6d6FCF2E42B47976c236F3d0d6F15423019": 1,
    "0x0Ce8a7A4dD77aE159520B4F6f9B5079d2aBe390F": 1,
    "0x08B571C651b185CFFae8d4c75D504232e9167edC": 1,
    "0x3B49D54aC8516509C096458D516F8fad3F58E7Cb": 1,
    "0xA036c61d8Be91a02838df996165fb3450cdA4fAC": 1,
    "0x36a050F329E7f495beaD85f7bfD5b911AA4E159e": 1,
    "0x367ff64dA0668D86E7Ac5d43dD9459Fd4CE381d6": 1,
    "0x13936AB75cF41cCB049dD3A6A8B52a89C9D79a76": 1,
    "0x0230C1E68d5a5b84582534eDAc3030669393fC9e": 1,
    "0x15e03c94d02D6Be593e82Fed43872970A8a07c1A": 1,
    "0x08267CB203b4Cf1066a81B0F218dDdd8f0E33E1E": 1,
    "0xB7C5d9B62C233b5C9b450AA53A0a1d289fc0f378": 1,
    "0x9C6b7588c4D7619Db6d018e36b75B578cD81D44D": 1,
    "0x977D3dbf93174f517a52736E1e556B79300CE3cC": 1,
    "0x9934C1269a94A09CfACB1f123fE8660CfFd0D55b": 1,
    "0xC3023FF1771394c8A37f6D75fb0dc152112E74Ab": 1,
    "0x9e11A062173F0B01a2b0AdB2504d60347AD1237B": 1,
    "0x891615aF0E913e3F08E7de3853A8180BD8fE27b3": 1,
    "0xcb0F952acC95998295D1862055403B8eEa3C3169": 1,
    "0x0B59E3e73dBe453E973A876D34397c0cB759DAEf": 1,
    "0x9577C95D024b37Fc8f9837aFac29748bCFB8d983": 1,
    "0x1f3DE51f4295BE1ad3874706dc85f8EFfD989a22": 1,
    "0x27Ca40b4Bb1416C7217662AB4631930Ad0119327": 1,
    "0xEE958e619F1F48108641115443b949266ac127e3": 1,
    "0xe9BfD280b873FC8c9551B8315aBe61134c891f39": 1,
    "0xFE65c00528faC68DD8834B5688A7381d4624c525": 1,
    "0xbed93646348D2A749F3Fd3aCB9dA3aFe38378baC": 1,
    "0xD74906C0Ae9A4834723871B6E9d650E23654F08c": 1,
    "0x6e8eE4656308297DdfeCE05b32f12aC24b1608e2": 1,
    "0x6F4cC594AD6e21635a75E0323Ebd519c2Fb6a6bD": 1,
    "0x77f92aC68bAb1ba8C475c3B7cf9a5Bcc521048A6": 1,
    "0xF49dF42f25bDB8Fe3141B332573d4ad52CeFEA75": 1,
    "0x35bE279b2eF0004aFcf4Ce83C0B5BEB605bE72f1": 1,
    "0x65Ba4f92D7DFA813DdBd849D9Faf38a723Dd9b12": 1,
    "0xbFCf0663Ec8eAbd2090Fdcb36534fc8352BDc042": 1,
    "0x5b20c339A17f02f4984AbBD9313894862988D3B2": 1,
    "0xc1281C3Dbd892A96C9ea5007F916b50b0e162185": 1,
    "0xa185Ef6833C17C96eDbDBd9e40c7Bd3680F19177": 1,
    "0xB1242ecaFa496838d797F4202ff4fD28d1398196": 1,
    "0xE60CBA37CAb441a268583F9Ff963ecC59c4964b3": 1,
    "0x799E8A81336aDaa2E2176fd9018EC51320073393": 1,
    "0x148C8b81a0A64a5100b85b2f42270D65827e95bB": 1,
    "0x3f7dD0B106B10D06bb4813715B4E24D9E626dD64": 1,
    "0x7D5a90dC04F1B7525cc5145C7B8BC8D918f3433C": 1,
    "0xb62d4D345Cb18372E3375eFfD5ffaAAA591726B4": 1,
    "0xb878772e076be1b7Ea98F42f5aA6109594906784": 1,
    "0x92eC90D6e692d39B189308621c9B12f33372dDB9": 1,
    "0xEb92Dd3D162F2eED45bb3190F419E53924B88bA4": 1,
    "0x4ABf36CfE4D3385C4670178aB2a5eA9B5314099b": 1,
    "0x6b17d279dc4f544F7f3B93346827D5B51fa84b0C": 1,
    "0x6Abc316192B43DC64c79Ec841AC9546f793022C4": 1,
    "0x727E634b43B816dEfa882ccfdaf80a51f92B72Bb": 1,
    "0xDd21a4290DeFe61cba6B0bC0060Bf5546f3740B1": 1,
    "0x880957527A57604C1222bb01beF0b0aC12fE7f8f": 1,
    "0x7C61E461dAB071D929247C94fDC3f2397a57b212": 1,
    "0x5Bb6Df21b9387D6dd7F9C4576BEa38C41bB0F07F": 1,
    "0x09BF12B246beA07D93dF742DecD5C3b41F701EEe": 1,
    "0x6539F6F0A3732010aadA006A5604b7e4DDb59b2F": 1,
    "0x651323817d479D40B49E2E63396F2fF47F225267": 1,
    "0x1a60DfB071B039c6e33dcb3220891C83DA72c1be": 1,
    "0x3f5f58e3131055D7030fd4f11B165a19f54256f6": 1,
    "0xDc242D78673A28abAf60B52BfC2aa244275E881E": 1,
    "0xBe38BFcD15958bBbcC30F4418ac949B54c385902": 1,
    "0x747dbFBcfb74fD5fc115896c758a2FDFf581c702": 1,
    "0xDf85b9146D73a852D7B900d1ABD974f2a8119f57": 1,
    "0x83cD226488308b6652487B3A18A09543Ac556499": 1,
    "0x750A31fA07184CAf87b6Cce251d2F0D7928BADde": 1,
    "0x450844AB03C3c9Aef4e81aa785f6730DECc2Fa64": 1,
    "0x46CdD0BBf655740df3051892cAEEa6731F4165e4": 1,
    "0x05555B1A39D6545b2483eB29805Ad8b00369972B": 1,
    "0x29578803742e65dA328AC1616b31244A3791FA29": 1,
    "0x0F1928e57a33d3f423C3a0B76c90531B4Df76A16": 1,
    "0x1ACab638f66307A46f1B466Ae930D7918542eE11": 1,
    "0x48D8fe5A468617FA0c5D76C210b26f7D4Fb80651": 1,
    "0xeF79d7B479e83f05122dc788707B4aBdd415aEE8": 1,
    "0xf04C8f815878Eb09B8E4602Ffe780aAC818AE6b9": 1,
    "0x74334B413325F126bD5e36f4773Bc114B6725952": 1,
    "0xD65bF5E2843463Ad1857cd1f464598d79332d0b6": 1,
    "0x7C62BE81B4372202E090Ee22cae8a8F643f4469e": 1,
    "0x66EDBB15621F1Ba62828D07885e12263ca184B7A": 1,
    "0xeC038448CC8F4576bE4E2dD3cA3B1a8Cff31D1F1": 1,
    "0xA2fD49D11af2a6d934E5e221d987F71180720aAD": 1,
    "0xD723127B0C9aFF7B08A8AAAc3F6B95D0716926fA": 1,
    "0x481775A1A694C31DA312cd1B166E353151fE0579": 1,
    "0x505a3BBa719f6A123e78c7637CA371dFFc549C00": 1,
    "0xEd24226381F5144562f0F50C69b7AbBAf5a2F093": 1,
    "0x2d94EF86f8842726f7829beBD76b0b0EC2b6163D": 1,
    "0xa3D6fB4E4812C101123B7C70482D17d75af23544": 1,
    "0xFB4547f59fC86cA9DBb838c17f795C37B6e89392": 1,
    "0x90077550db8D23f3825634C3497AD3a594013137": 1,
    "0xf2FbE258ce7c249d1563F6151201a5Bda1e43cB3": 1,
    "0xfE97f3ba5dA378Cd83F94B02DE8358045491D48f": 1,
    "0x1425C621817B92B9aDc14D9Ab21f685c7B52a508": 1,
    "0xE5050E39ADdF3475f0D7Ebb17C6bde117f0590E2": 1,
    "0x14f13489d3D9226804c11469670Adbe3836986D1": 1,
    "0x5C4FE960950BA0E09A72869C3D51fe70f07580e0": 1,
    "0x910431666eDc116E2c6018BF4cb07dA5ebB7D6D6": 1,
    "0xBDC2F65bd40cc952fDb4243f8Bb311159856209B": 1,
    "0x6d24Df1584d329146e43b98228A26d0c5adeECe7": 1,
    "0x0a7a8D210318C50ff8b26467809f336a7feC39A0": 1,
    "0x46f631E523C58F2cB511F43EeDe65e62bb7FAfa4": 1,
    "0xDF39a51D992d7FCF42a627229aCbCaFCBF0566bA": 1,
    "0xd595710aedABa1d2e18BFdBAB46Ab796664c6D89": 1,
    "0x7c74F84ab9d211b0aD306fd793c593B744135c49": 1,
    "0x32EdB603ED21775F8d6063C77bCcc6e3604da2B7": 1,
    "0xCeCe5bafda337d3a98CCa669E022c751547Cc1C4": 1,
    "0x43A6D7c0Cc3fa2A6d4654A948109Aa6D418eF702": 1,
    "0xe6A5f1690FcDA05D9BA0A663B6E7ddF3c97EB7B1": 1,
    "0xB7546d1B04151e1E96D38275aF66eaf984fDAD2a": 1,
    "0x4831Ac9428351a5C5227425a03A0c93D00Db5943": 1,
    "0xdf36a05Bc6245948014baEf1d1dAed867b588bd8": 1,
    "0x8798A04A2fff6F417c1bEE147acEbDe8EA759526": 1,
    "0xcAFCDEb15A0012063AC62A721C14dC2eeF257a58": 1,
    "0x5F548c5417D09bb9A75c989A9ffb324f2c7d660D": 1,
    "0xD395fc430D413B84f5330F942a2559474A7Ab9c2": 1,
    "0xB288090E6b2427e2e4b52AfB0590F0f22eA0f6D4": 1,
    "0xC32Ba9452cb2bCFa2462686F7a2b62811E3a4058": 1,
    "0xCfeeF71EF5e0374306FBA281C5978a4C70cD8207": 1,
    "0x0ED39b4c8ca3F0faf74c780e8EC128314Ee7f514": 1,
    "0xa8B15Be830ed58Fd9D9D8a275a8A070E9eeA9cd7": 1,
    "0x159d7bf20136eb23991BB4B0Dfa974cA2051639a": 1,
    "0x065C84641EE62d032ea5F20C49d59817C87A2747": 1,
    "0xcfF44ADa03447D242b2b552a1C02aA5B80a8d8ed": 1,
    "0xbD8C348dfAFD65FC730029bB2D7657Bd6A265F5E": 1,
    "0x0F737Bb605755f06063D47d987629E5198c707ff": 1,
    "0x67cA64e6d0A97BaE9D85B6B005a0CcdD876aB896": 1,
    "0xB16B4016F3ca82Fae2E4fEB2c6F5E8D414d274c4": 1,
    "0x23423cc57E2B7b204291dADb78c6Cc95B7e4ef56": 1,
    "0x115894b2859E58419D1eF4787A79a431CE2615C3": 1,
    "0xa67f71D39574b6cDe8bd5fae38D9E4c7a55e29Df": 1,
    "0x67cc642f32D724724765DBC40472a3d520b9e02A": 1,
    "0x01bD8AaC958c6E728557bcaC756273ddD65bF19C": 1,
    "0x2B0386bbDd314d8356C21f39BE2491F975BD6361": 1,
    "0xB0D8777738c5a6C26932B3Ef0862cBF5fA07CaaD": 1,
    "0x6897c2AD5512E273Efb57Ec6DEB96d49CD83Ee97": 1,
    "0xCfCDd074DC974af94fA2b9d56b6A213c0D96EaF9": 1,
    "0x5C6F81fDEdB612ff12A38100F037370b347BC148": 1,
    "0x6793d79202DaDE972DE66459B7f9928AB7ef3a26": 1,
    "0x5d1477AE8ce136463d8A0a0AC5223d7c37906D3f": 1,
    "0x7B220358c3EC9Ec03b08120DcCEC56F16Fbf33a4": 1,
    "0xB94f7Ed85B83A65709557433dF1c3c8F19F7c94f": 1,
    "0x650B0E3864aFa96877A2493B495FE2436f725adB": 1,
    "0xA95e79bF63549D33d7320663682968F1030a225f": 1,
    "0x5bD2B53CB6B51B4Bda9A23Ef46B76A501AD12e63": 1,
    "0xD297fa47bd30988358Fd6A6C8fEDdcCF957Dca28": 1,
    "0xFcbE1f6ec1C26c8F48Bd835d650A3383eA1797C2": 1,
    "0xfB19666be4530E601D2DF9D2CB726df0ddEA2c27": 1,
    "0x3b0AA499Cc6acDE1d4a7433dA6968d7bb8BD8509": 1,
    "0xbf23B32f95694920c663c40416899e284aEa1284": 1,
    "0x36FA2FB2ea3Ee7e6203B126d4a58Cbbda03531A5": 1,
    "0x8A63F83B0e1Be77934b5A2f812a0ba4cDbE1beFb": 1,
    "0x03D1a11f5e98A02EcD31962dc787000451E0c1d2": 1,
    "0x8EE376DE530fB9a734DF676e7E4342B48355F483": 1,
    "0x8b265dA0779172CD37F1a8dc786B828222902d74": 1,
    "0xFdB26046b3138F5a812FffE027527c0bF9C4d686": 1,
    "0x7d989ce97CFF246E23b2c8e9d431e922C3e85dea": 1,
    "0x302f6ae056d30774984c714c161D54b538381257": 1,
    "0x8754E1eD406a72f8dECa5b4c9654Ad2Db698Ba83": 1,
    "0x5dfA3092bE17f441F85A4B3218A7675f0EFCF9A3": 1,
    "0x7C344832Ec4142108f6b6Bc49CE884281E6A19a3": 1,
    "0xCfd648EB66b736351f48DBD5a1752708371c10F4": 1,
    "0x53562e8eCeE54356dc89Ad263A444528B34D6c80": 1,
    "0x2624e6BDcE94156695330a43617DE537518052F0": 1,
    "0x44C831D87a421529f00E07C3a6615E5dB9Ea9563": 1,
    "0x9d977ba613252f67Cf0679C6c91ce111FDF3998A": 1,
    "0x1E936cB14D3dBE800833492C95AECBFd7EF127c9": 1,
    "0x7d5300e22DB1B2850Da1bEED10a894893731c1A7": 1,
    "0xe568ca143592DF06A7EABfd4d9D563F1289dc607": 1,
    "0x7AF9C75996931344c81dAF2EF15456a2A379993a": 1,
    "0x052EBa329deb78F43E2ddb7427a7F3517af3051D": 1,
    "0x4921B855C1DBC98351486805A3d4f4F0147b43D6": 1,
    "0x6F4e3617db82bE3aa2f470De50051F7B3f95Be9b": 1,
    "0xB7AA297E076b0482BeC85F78aFb0EDcABDfA97Ae": 1,
    "0xE23A10c5212922d5bfaa5322b28e8cFeA0657e88": 1,
    "0x47A3443937AF19ACA502042617165767B278Ee35": 1,
    "0x91A7CeE575c28b6e0252F600Ae0848E4162A21aF": 1,
    "0xE64777Dcdca11FD8BDe96eD05e7560ae789504B6": 1,
    "0x3fEaa35631D40a7C17E395E6D2EF16f275183Afa": 1,
    "0xD38C4EC9641c25c95ea1612224e19e4FB91c3333": 1,
    "0x8C2e66053c53381d642F71cAe8eE944D0045da21": 1,
    "0x3F546287B708fbC18C899D9257a68B1AeE0943aE": 1,
    "0xd698496b909bcca575954E13Ccee782c01548f56": 1,
    "0x612181772106AEdC4114c939a36A2795Ce101aE9": 1,
    "0x5e9135D9564400dD49d8B23abeC20E0F317C4c22": 1,
    "0x4b37c9a6670B58fA7fE5cA6Ea4D56C7e9D1bc245": 1,
    "0xb1a7993db1B943c8Ceb99E5e526fe65E133f63ec": 1,
    "0x60a650e6FfA4Fa48582a1DA81Cf7D75e499F324e": 1,
    "0x5DC610BF74B4e6B299306d70BFa7e2162B1d53fc": 1,
    "0xe4ae3533810F3ce97DD9b89031DDCC97bA91756c": 1,
    "0x2DDfA151Cb51B6276982943C85ac123cDcDFa0a5": 1,
    "0x9979d204e93229c4355431dFB520e608C81bBd0c": 1,
    "0xB337dB5f56fB84CbE3286CA8073B6Aa16c64CF15": 1,
    "0x8Fb1B4930A85c3B5e95ceEC68cc5567385000Aa3": 1,
    "0x7A70536c4D695B1Ec9DF972e91461E834bfB00e8": 1,
    "0xB09861246e4149AFa18581449C0bb6D4F56bfee3": 1,
    "0x0d5495A58D7C3b093b76e19Ba81517a018FCD3da": 1,
    "0x7E677ae5e58123193fd628F20096d98E7Ac6f5e7": 1,
    "0x7ca279F052687BB1fE480d1f57ff8A2C71016399": 1,
    "0x704Bf432898fcbc9C73F044a59F5A3E6cCC7615e": 1,
    "0x68967A11E9B41e16cc57eD00A5Da4A1743934D1A": 1,
    "0xcd68e1BD28E2529756880d68E8875c9887110cE7": 1,
    "0xBb9A3FA6E2CDA60C4ce2AcbF4D5D56A863120DF0": 1,
    "0xFf78aa70124DcdC3Ea434AaF0B9EcbF51391fd4d": 1,
    "0x2BBf733a0d8fC4978C8EaecA37c91DB76E086782": 1,
    "0x5c6994656Bc755a9dA422D39cF269FA8e8Df41cE": 1,
    "0x0C96bc8F81baB0eAEa011C48C090fcAcf1ca99e2": 1,
    "0x64331d074fd5118abD80bF484f9Af63E53c21698": 1,
    "0xaA85A9A085e2e7920c296CF739B9e755265DE8De": 1,
    "0xd4eF1aa497A13118d3874B65472d3F7F613F0fc9": 1,
    "0x38E6c18ac5700FF11304024FC74308aBE0Bb7485": 1,
    "0xE52eae27fF842dF16eD9946c98310A10497A2Ab6": 1,
    "0xa49224407BfBcFcEd35D70657f44041b8e162EF9": 1,
    "0xE2194F9C5997079C00062c74A500420eFDeDa62A": 1,
    "0x09312D26C78E4cCA459c89F1AAEc89e551416Aa2": 1,
    "0xEE5B514082206281f261Ca6dE9081C355c017739": 1,
    "0x4E19144F05D1901800be2077C48f953199DD4b47": 1,
    "0x1Dc410fA35B35E535f5862cfc54dAcdCD1Fd9143": 1,
    "0xcE4E346D7E0b0C93a832dDAb0D6B9753Aae75219": 1,
    "0x59B65B5cfdAB7032dBD82F1cde12C87c2c0f031f": 1,
    "0x58A4B14A6f8706Fb4C118ebce63dE533BA19C613": 1,
    "0x40941f5619974C639fE13fe977Fd92805fc10Ec3": 1,
    "0x6997A08527e46088d942Ebe4E5aff0Eb4bBF8319": 1,
    "0xc6Cc7f25Ba045B8c08Fb84aA1494b106Fb6824a5": 1,
    "0x21357bdA562d6861E87bB7dBf850F6AAfE940919": 1,
    "0x95298343aE03528A6B3C5d211005937f4987b51d": 1,
    "0x1986F4BCc6b78d40e499E928a910DD7bde857734": 1,
    "0xc15AdD7Eb1BA708BC7189CEf6A4c47200b77a52b": 1,
    "0x0d6cFC385463Ff9E6aB20a4A4F71F111B53a0942": 1,
    "0x230648F035DA16D6d9ee3dBdBf3cFca6BD261EbB": 1,
    "0x4A29367c5Ae9F84eF03E447D1f7deE8e6b16229D": 1,
    "0x4688b6f3C384C3CFFE95c92f599213De4a9fA9cA": 1,
    "0xbf5aA87878c968c9D062F51cABD04b66023c8E36": 1,
    "0x4824DC193DCA8Fe6C0bAD6dabBC7385aCff10E53": 1,
    "0xF86a588f3604e3b12899f710E3D572F76ffB94B8": 1,
    "0x9568EF803D55005AD31Ff6cd14924B5e0172dD9f": 1,
    "0x6c853Ce4468c157031bA9a3394867B57d081E3e1": 1,
    "0x399797F2C6e391DC4B0B1CfcD249102aCc1b7B49": 1,
    "0xAD8798d1C0D85a94Dfb8C0B033ECaaF38029bA94": 1,
    "0x268F5Fa2aDeB3a904FA60D4FfB904738F0dfE3b4": 1,
    "0x0CaBab448FF897EdeF2013C6F7da320E83e9F2C3": 1,
    "0x0d361A88eB6eC69fcf26b5e38776e917d8E8A9C2": 1,
    "0xf87D7fb5540966b495DA294cA5F6D88D0035eeB9": 1,
    "0x01D4A6b3a1409ccEc385F0becf128cc32881eC5B": 1,
    "0x5c5A594fFfa1c22733aC614F0f9e1CA7650D318B": 1,
    "0xb35fd339111929c31339d077336831C00A11579f": 1,
    "0x0f69Fe8cbB954A9eA1Db436eC5A4943428d81975": 1,
    "0x56A061f4Ef706E1f6dCdBcf7e10e4340c1d99bBD": 1,
    "0x51a4d2E8E10A5B7E5519876f2567089186a0bA71": 1,
    "0x29fe85f610c9038639E3Ea0d49c37363A8F63927": 1,
    "0xC7C6A1F9D57d2Ee64a2c8087b0275241034f3AB7": 1,
    "0x714a8660f0215E286F5bdaF933ef693Fc3cdC74D": 1,
    "0xBb04ABa4b7637dbfe421fCa08b145CFB0de390e7": 1,
    "0x250D6544d18e43fa807333Eb0A747A62F5b25aA0": 1,
    "0x456Bb48318615669FD49DE46FBded848Ca50D016": 1,
    "0x585a8D6138F143c7Ba47d65946dD77B19FE1a14A": 1,
    "0x47A366519eaF385804A404697eB8d5cb1Fd55D8B": 1,
    "0x4Fd5737C0A074eD994Ba0AC60CbD383Da69454C1": 1,
    "0xe164644e1e8Bb14BA8aA19be1B78ec6CF22F7ca8": 1,
    "0xF01BCb0090CD0f734688CE77ae067dA58E9D8005": 1,
    "0x4322AA46ffEd67809862Da613725728e2fb8eAe3": 1,
    "0xE7380b7BE1ed475dE78b07033A8ef4DfB10205a5": 1,
    "0x874FC12eB80efeE30EFad4886671044Db6259FCa": 1,
    "0xB3E08599AC57666BE68dBB3D311B9C607900A83b": 1,
    "0x68A7Ac13477AAD590982293FEEeb786A00276cF2": 1,
    "0xd6f28BD94d1EdEfdf8fDE8f6F10094fef7e590c0": 1,
    "0xF8A685702347377e396486967399f33c6c0C0A29": 1,
    "0x75D37c2D723a4bC44302927e08FDC24a821B5227": 1,
    "0x86e0E35785f54A8c8e901f5bbD886db612095e92": 1,
    "0xC04be3860e35D34D12995F36D41306feFdF63455": 1,
    "0xB7a90778C0057a8254EfD88EDb97BaFF1dA468BF": 1,
    "0x2819B496a8BE47F14778b90f7264A7c5456D0333": 1,
    "0x78Db897e7C8b16C5A51E17fBbcf905dbFB9A7E10": 1,
    "0x8810F4C54c622fe4c5Ec723F05D7cA10063FCDDB": 1,
    "0x07058f4BdC6BdaeE93b49fe30Ff11FDa646ceca0": 1,
    "0x1b4b91bb747176451F0F7f73b636baBAfCF31Cc4": 1,
    "0xB3D8d8cdd16B5026fbC5cD6A6770Fba607a8D02A": 1,
    "0xBC772CA9864f37a85e6C9dd78Aa21F7289d1A2FC": 1,
    "0x7aB1A943bf83315E3b82A0851D8CefBA6AB938F2": 1,
    "0x906eFFeEa50Ec23F374bCF8a03d0986EA2781770": 1,
    "0xC6f9cCbd26eEd734A15559841c23b697d9073E38": 1,
    "0x66b290362Fb9E5C716A7544cbB74DFe2187e05ED": 1,
    "0xEc9E1140291EE05009F9CC8201198BC3E9081313": 1,
    "0x0bcCcf68faFE41b5494d7FE81Ab018a2A2C06843": 1,
    "0xE4D310C71eA8FEc7B9d8Dc4ba94Eb83E86F08FFF": 1,
    "0xAB4Fd15f501d837BCA476cC7c5bb199B0f64C01e": 1,
    "0x287E51018d4cA57b2cB36d02d19e09c1F3aFb4dd": 1,
    "0x1e6EC0adB8d5B769C7b94Ee74B1011cF60A54008": 1,
    "0x3972680cc2c4a0fE1A0C248A0334A8B28741f65c": 1,
    "0xac65102BB7d79488a32d84C5d61EFE27b19FB9F6": 1,
    "0x7d6db75e304aCCbb35D16a3E7961f54D0AdB802c": 1,
    "0x4685b377e1c85620C6E2BA79A587822C3b6597E5": 1,
    "0x1C5762F9899B46c19d4C9B8FBDD38D35856227f3": 1,
    "0x2dba88cB3B435F99a3e58B6E0fE450e8f1a3F20F": 1,
    "0x94eF50aFAc9c04572813F36Fb8D676EB400de278": 1,
    "0x90A75f0d66Af512033C29467be5cca6068229529": 1,
    "0xE332de3c84C305698675A73F366061941C78e3b4": 1,
    "0xcF0fdF0E8bE94065927a4b04627f1BbeDE9D838A": 1,
    "0x6C63f5075517FF3ab51d08F5aB51E98BAe5A36a7": 1,
    "0x4EAd0eB0166e279ab7012590E508dbefe622e686": 1,
    "0x9d1D2a0B4a4c01e76b8A62b26b9c3dd777A24c02": 1,
    "0xcB96ba0031427D79bc9BF6162b85126E791F4A70": 1,
    "0xa3926D4b61Ca7eb811C646a435d521D3Df0006e7": 1,
    "0x5ebDC6f4df9fa2eff4C7D543ff52629520859bBB": 1,
    "0xFdDFeE05d3e454133B059D7ff9c3aFBaFD9aae69": 1,
    "0xe6B9F6b9cC3DAC911976008b131c2Ca634D576c5": 1,
    "0x76a96f96255A7984dA77Cf36544F9206dF730fb2": 1,
    "0xdeedF0491Ba535F2eab24ff92AE1a26cc3A8EB83": 1,
    "0xF46cF8D8A42c9db6A072545e306C8fcBeFFA61f2": 1,
    "0x78827e75Da8c80689FCD5af11D036Cd45E414072": 1,
    "0x967011AaA4052d43f224fF8f46b5cd2eB3973842": 1,
    "0xAD64Bf3DbCC9A447fEb81F82594C12054F4C75Bf": 1,
    "0xD6b4E675E962065682f253Dda7a41f0201B1596c": 1,
    "0x770bF5Dab4B8A83338B49cAb2A1efB8cc5fd9CbE": 1,
    "0xb2fAd9a8448EFfd2CB73fA705C041250ca9f57eb": 1,
    "0x56Aa0CfAfd65E6e60380D364e4E27c753CB01032": 1,
    "0x68F31058cea08ceA21bFc3426b0E24CF062F05fD": 1,
    "0x71F18A49aeAd51ea20b9f2b68C8Dac026dA5BC14": 1,
    "0xa0d45B72dBf8bF4e7DF391687DE7b262A78878eb": 1,
    "0xB0fe1e71a88b115f3b7C4053Bb1B74C5f2B3d19f": 1,
    "0x730228dc081E56f3F359DC43333D57203c52CF78": 1,
    "0x8d9e7cd6ce495885f15DfC1802b9feee5f2c7ce9": 1,
    "0x5D0716f284ed3e53f478b3Ec9B2936cF5b67BAc7": 1,
    "0x4E68C897F3Ae937bEB0e7ed7e156730D4E56d914": 1,
    "0xbC7c4B0B7c650066Ca716008B511331C4D866693": 1,
    "0x5ac3cc3f4e5646125f46933acB54189871d73691": 1,
    "0xEa18f4A41CD2197Ad3d7Ef0A60171CdeD675fa74": 1,
    "0xd714A018bBBe1649Be08ddbD11C96c13892F27Ac": 1,
    "0x3E3fD41aDD7dE67a12CBF9575d442826B067C71a": 1,
    "0xaB9E2b1f1CA3d7dEdfa53E5b975416e816023F40": 1,
    "0x0743FABE7d0108bfD62183729490072388BCb9Db": 1,
    "0xB596a252E04E0680Fb9133Ae3e008377C568e852": 1,
    "0x1FAbFd128d498fEF7da0190ef416a502375bf59A": 1,
    "0x128Db0689C294f934df3f52e73877a78f2d783B5": 1,
    "0x8724D3B85E5dE2b544D8423FF7979dc2F157D292": 1,
    "0xb85eF4488f9066141202Eb51210e4E1D9Df02F53": 1,
    "0xFe1B9DF4e601Ef59B0b9bB9Ae0B8D8cf0D1E923d": 1,
    "0xdB164584d2999AeC75bAc42cEF62c86df9B300c1": 1,
    "0xc67B2bb2885Ae63E5B775e51C36E68B52219b724": 1,
    "0x5863c43e108196315dC7efA32FC04ed091c702Dc": 1,
    "0xE1044dbAbd90D663DBB90AaA8A71098E923ae9F4": 1,
    "0x02eEdb5058B62dF5424c0B3072E5B414afFe0E8c": 1,
    "0x363F325180DfC46899D6bB43BE23206258255295": 1,
    "0x496597FF693971FAB33b542c0Cb750BDEC6062ef": 1,
    "0x8d5e39042EB4a456Bc4C4fEcd66ed45EBDf1301a": 1,
    "0x6E3dc7Bd2Dc4a4353622a4B2c1ddae829208b6e5": 1,
    "0x5D3E18d69c7b3A1276562f75E3da3671472164D6": 1,
    "0xED5FF04e42ca14c8D2F1b158A4B992713d98103E": 1,
    "0x44f9e8aB12e7E610eC7ce09A7d7Bc87AB9Da1B6A": 1,
    "0x9Cc7D3A919CdEDb489c0Da917E9F4F1D44Fdd732": 1,
    "0x4b62E10501BE8FB568Dfa60f37bb52AE734f4FBe": 1,
    "0x5044C613ca64DF26469C17ceB89E4E1b7b9050ef": 1,
    "0x3C4dE47214da95c8d028692A82E187514ec7b409": 1,
    "0x4F9ED976581a17f770f517e80d6d7A040A509A99": 1,
    "0x18aD0aeDa529D505AB14517716e2b6cEb3C75B96": 1,
    "0x2dF3D52FCCed0BD59ebf59D4b67f6DDbc1DE8bdA": 1,
    "0xd88d8421c83e4E786E7359A4C147241eAb2EAdF8": 1,
    "0x77532247899b679Fad53613055832CFE6e7e60B8": 1,
    "0xC69d7069BB36fEf7023314D233DA0C5326ec1377": 1,
    "0xeADB0bf6766D9d99CE800Ecdca66B374303C380A": 1,
    "0xB5c2a0c46d0856c859150ef8E74c6E79aE2C6bB8": 1,
    "0x266013E14C9bbB75949C9cEdDc44B07b23830F74": 1,
    "0xdec36DF05cbd61e5cBb05454180421DD025839d4": 1,
    "0x301aA246C955780ECD39583beDe47fa8AADb4fB0": 1,
    "0x62176A3Cc33eca5C8a738dF2fA5130E31757c719": 1,
    "0x46c13a13Fa3E8c6C6AdA7861eF40e9B6abC9DBA5": 1,
    "0xA042559E1E472EdAbFb4967f264a68ef7b9430E4": 1,
    "0x18982f09856A0017c53F3122c422eBD52E882166": 1,
    "0xB9b36B9692ecD2D165bD9FED115C6Cf67BBB848a": 1,
    "0xFB4ad703e9FB8843E187C9538d11bD7a91be034d": 1,
    "0x379B0f96Ba973a6D9A6B016729B8D9cFF439F3eE": 1,
    "0x0FdCCd11B20d5D160831149e11FB53a40f268303": 1,
    "0x55d9D156190cb9D19e17Fd9fCa8Ba553aA0B6B88": 1,
    "0x325FF06709527E38062FCcD39F37F7108ceE89f5": 1,
    "0x41B81f8751A71ac313dB258d3d414b17899F600c": 1,
    "0x3B1F2b1E1E3547E509F1c32E0162681db5262a0C": 1,
    "0x7801e3fb9b49032213f604323Ce41c001FA4C664": 1,
    "0x1FCfEDEcf0c1353Dc61E84c66A162A17f1E10220": 1,
    "0x5dca92c3b137E78e7E2D96EB64d3B3BFd0eeB775": 1,
    "0x2f410a1bB25912b159726Ba52a18139E0fE8daE7": 1,
    "0x6867B647D4AB5DdF0743c3AffBb8F1367c8809ED": 1,
    "0x9d3F56186CE4bA86214AE9127e07491f2449D698": 1,
    "0xBe73FA76F7E34675B849c36585d79e5Dd770a833": 1,
    "0x361ba255A10938D7Fe28234Ca6999e2141639E5C": 1,
    "0x9028EaF16E267D7a1B6a8e329524814Cb8f69FA9": 1,
    "0xDcaE87821FA6CAEA05dBc2811126f4bc7fF73bd1": 1,
    "0x1010595F96Ab62b31BfeAc411Ec5f8f60DB5DC23": 2,
    "0xC264880faD20D56Eb6C5c18BcB2F3d50F72c7663": 2,
    "0x5ea9681C3Ab9B5739810F8b91aE65EC47de62119": 2,
    "0x8Dbbca57Ea56290Efa14D835bBfd34fAF1d89753": 2,
    "0x621Fa2A2Dff1012CE2989E583C407D060Ef59754": 2,
    "0x695626A661831A618ae846482FE8abF6c8d4529C": 1,
    "0xBeC69dfcE4c1fA8b7843FEE1Ca85788d84A86B06": 1,
    "0x0a690B298f84D12414F5c8dB7de1EcE5a4605877": 1,
    "0x3f849f47f5B372d80407e442F360aD7b17F5FAc4": 1,
    "0x2Debdf4427CcBcfDbC7f29D63964499a0ec184F6": 1,
    "0xa20b4B33917d5c1e95dF952C29c6410E2BDA29A0": 1,
    "0xaf469C4a0914938e6149CF621c54FB4b1EC0c202": 1,
    "0xCe90a7949bb78892F159F428D0dC23a8E3584d75": 1,
    "0x79a6765E192C0e3280d1c15767D98f99fB4a9404": 1,
    "0xF5D4a3b9FB77d1d5600a10E0e1C4eFD12F9be751": 1,
    "0xEC6d36A487d85CF562B7b8464CE8dc60637362AC": 1,
    "0xBB34d08c67373e54F066E6e7e6c77846fF7D2714": 1,
    "0xb616262348ad8AfFb0B368e95284e996317C9098": 1,
    "0xcA04E939A0Ac0626C4a4299735e353E8DC5eF3eC": 1,
    "0xB88F61E6FbdA83fbfffAbE364112137480398018": 1,
    "0x54a973a5da508D551EdF9EE50842E7C36FAa1d87": 1,
    "0x260C776f721e6281e51f21aada74F6407cCdA236": 1,
    "0x0F0eAE91990140C560D4156DB4f00c854Dc8F09E": 1,
    "0x1529d2e640F7febD3e9DC40728cAc060a2739d70": 1,
    "0x341D13B93a9e6eC3B9b2B5C3EE7747D6eC95Cf69": 1,
    "0xB692704d590e224C67Fa96830C4b2526fcCAF3a3": 1,
    "0xE1Aa5CE07798306293E72CA2e187CA75a568188c": 1,
    "0x6Ac40b84f5732cCc2d21ebe71f2ACC4140314843": 1,
    "0xAb01FD76F775CaB90E6c66f5a64368d073f14cFE": 1,
    "0xa7fB80b759Ea191C19720f531398663b298455fD": 1,
    "0xEBE326d8De3413F8132518dcFd45e6cBFf7E5c27": 1,
    "0x1064aA646A7Aedbd40816Fc0C35E044D0244a764": 1,
    "0x162f3a373A8605Fd0dA52b1b6D44dc8BCc97F0AA": 1,
    "0x81eaf34D48Ee1e093117c358699aD91497A0E5D0": 1,
    "0x40df905daE2877a8E908Eb0b2db089332865790c": 1,
    "0xf97987021d74F60D08534D9f7d4B48Fb33837B0f": 1,
    "0xa8e705B1fa3850E41f2d2766b14Ba3032ab16Ab1": 1,
    "0x489a0f8Be88D6025C88Ce431636E7DF60518dF68": 1,
    "0xA1332968ed8044eBD3f841Db72067BdCC04F275a": 1,
    "0xEB9122ade700C4624b46aEd1bd896329c2c05e42": 1,
    "0x40599AdCFF4599c223E4589cB55b4E148C3E4436": 1,
    "0x255C8BaB632562e663fE133ca240B5424A50145b": 1,
    "0x607259a2e2cb34D1A8F4eE0Ee3F6A98785BeE145": 1,
    "0xE7820e30f55C042ed6e51211BF3F93ee617834d9": 1,
    "0x348ebfA7b44af0AD9A5a9870cdEC540be548b66c": 1,
    "0x48766b6E4bCde79AD8134D338851E4D6739DE113": 1,
    "0xBA37bCF34B83bA79c9B72Efa074b79c2c011583b": 1,
    "0xDB842C6Df048cFD661e8c33a73bA64470bAE22aa": 1,
    "0x11360F0c5552443b33720a44408aba01a809905e": 1,
    "0x1a9a0FB85C510A1b9944238216C3DDBB72f5Fae4": 1,
    "0xC47EE8b101aB4a4F45aa2EC2518bf00d3ce794dD": 1,
    "0x792B37c2244ECc0bc18aD193498f17B27EfAe7d9": 1,
    "0x403AfDf9Ea925D3b48E719a44610da1679a57651": 1,
    "0x7cDc15EBa13609A244e96b6b9e4C4a95f81ec953": 1,
    "0x9Dbe56E65961146525D796bdc008225BD5915a4f": 1,
    "0x10c5D48B6b4B64d2f6c5e58dFf55AaFaBdE17709": 1,
    "0x8aF9cCfD1B203186AE754b4FcB0c237F534Aa9e5": 1,
    "0x6057bdb3D8F6Fa5F245a37443fF3732c5498e3F1": 1,
    "0x9E5435685733787D1Bb5B6e434353C65cAB7c21d": 1,
    "0x037BF6A1E7b137f824f46313317e033ffECEb613": 1,
    "0xE98674193a2116000eFCEad7762E8d5E86746Fb5": 1,
    "0x4B2b4142aA81a8a7D6052D0c302835ea99C4069b": 1,
    "0x9d04D655331c41E031b9B48A80a20Ca23b812b14": 1,
    "0xD30c8036B6254F494F20248A8d82FFcAC1A739E7": 1,
    "0x2F85a796B8196AB76aE913fBF1B3edFFab20195c": 1,
    "0xe1741a81904828fA8bA062dB316016D19b2F8BC1": 1,
    "0x5B66c925A37d1Ee62cb5510FAc282c695c2F334c": 1,
    "0x8B8a03f558d9e3be57947cCeB8F197FDDb33C05B": 1,
    "0xF90ACe6D1a2437825Ccc59b263A2ca8B85C7A2E6": 1,
    "0x3fee78852558A5A5AA1dec5bC6367Ab6483b9FeE": 1,
    "0x48f244cE490F7D6970F4C129F640ADbb32A3Dc76": 1,
    "0xD954D7a9E291A59dcf300de11784780Bf85A1E31": 1,
    "0xe215caBE3e0e598A4f4B5De954088689A95c7420": 1,
    "0xE2039789c3392C292f194Da98dFc2A75a0068e60": 1,
    "0xC43f35398C1aD990eE761AFdc2aFA36a10D86B79": 1,
    "0x84714a7da41E7f57273a6DCd770566aa63772903": 1,
    "0x75aFe85a0142581904ef4FB993348c71c1c84138": 1,
    "0x6B71F02e474a0005Ef2B4E69475Db0dB2091033d": 1,
    "0xb42B4558E8CC27f9ba298f756f344d2Ae24CcD44": 1,
    "0x048F05E3093571beE97bbaE3D4C59a0ba2C856C9": 1,
    "0x86A435699d17d73f7DFeE044eEaA1e2fc5D8D9B2": 1,
    "0x0065F4448a0AcdaeD557edD4F98713768fbFEC5D": 1,
    "0x68A5425561836d23569f7437a27d16C6332147A8": 1,
    "0x1eb0473C1f79B2BBA32fc052d98370F0bBfd73F2": 1,
    "0x48B94E0C7d882315e6C68718Db2e80E47ed99724": 1,
    "0xfc321bFc3a015Ed045E99f08269Ea2b828C16314": 1,
    "0x57b7813E75E7918CC28359872237555aA2c0F65C": 1,
    "0x48910A64005CFF019885Bd7B277e0A69a94Ae11F": 1,
    "0x2EA267fdb526F9dEA1131D2AEAE37a972F5118b0": 1,
    "0x357FcbCb45756Ef2A7a120A018Da692bBFb0d2B2": 1,
    "0x88DE3dfB4b5D2990d014c525A43CbD38864c9d50": 1,
    "0x91F7Bbb102BEdeC11D34294B2d18f535DB093889": 1,
    "0xDFA4D3dd91f5eF60Ef9Daf5B2e1E32A83c403Cfe": 1,
    "0x3E457ED4B314CefFB512C9C66a0D2eE84353593e": 1,
    "0xC07Ee335c7F09b942f6ECB4B88f622ae77e33303": 1,
    "0x51510CF446DB43068507b6D9EfD9b33Ab5238451": 1,
    "0x3Cf35c4f2AE545fbA29434CD8522D71deAC3e17d": 1,
    "0xaC9ED11E7420cc048F796E66a449D463793f40F1": 1,
    "0xA5894f85B5BB5145a69c3f4a41BB4918a8463701": 1,
    "0xE82B0c6B510Aff2cfcC3d87Dc647f4DbAa03fa5a": 1,
    "0xCa4cc3be212559E7BC7D38cF39D346C74C53e4E0": 1,
    "0xBBB67Eb638977cf5611F130494C071B552B44b35": 1,
    "0xbc44a57f1D37d10f03caa746Fcda0D0e10A3a6C8": 1,
    "0x144D18679FB2F77ADB91c43b776aE0cEE62a0b04": 1,
    "0x060b7Eb846b99C169de9e54d592E466aad7105D2": 1,
    "0x7c411C89c87b58D8C337B28f4Aa109dac1CeFD3e": 1,
    "0x8B8d75EE29f3B20Ea647dA8c99F4d2E0849478b5": 1,
    "0xBe4926faA3aa66742594F0468a901dA2B82B36DA": 1,
    "0x2c45f65D254E85584B47ec82450C91A30CfC6BE1": 1,
    "0x48fcb0e61921D8480A63d82926D3Ea80CC0D94d2": 1,
    "0xe670528F4B7187bc57729eB0216d28449b8fCB04": 1,
    "0x4b854dbe2c4Be8690Be9CfDa88D76b90B524dCC1": 1,
    "0xA354D7BF4d121260C4b252fe61f64Eddf3F8CaE7": 1,
    "0x35b863978CF0DD809fc8451ffEdC937b49f871d0": 1,
    "0xfffEA3A6b849D1CF86A32e2105214EBe90FDc3f0": 1,
    "0xc973787Fd92fA395836CF87fe05CcF725A7184e9": 1,
    "0x5e6f522249eac6C1215271951426fB87c219880d": 1,
    "0xA122dF26477d351BDE8225b5095E39294c730012": 1,
    "0x6aB7B619E11dD533a2b01c51D5D6Db37829b2706": 1,
    "0xD3F4328795A02F31ae1323716781Be153F23A11D": 1,
    "0x9752669AbD5B7c5a3224bF9a3049d7961d25031A": 1,
    "0x501Df7a75b7867BDE80C02f2FF4cE4E1c571bf6D": 1,
    "0x03261850992D1381179d5Dd2B902eD94b9322374": 1,
    "0x54c93f123fCcF995ab86f02ed93b50D8f53666ac": 1,
    "0xaB07BBe110B7ed5DdFeF66e84ac738B0469fd590": 1,
    "0xdc6adDF985F6Aa3022e387680E5c928466BD6579": 1,
    "0x02803ab02d4bD486E160A1Be97d0Db44450f0C6f": 1,
    "0x72Bc237Cf598505dC00D4FCC23B1663E206455A9": 1,
    "0xd686166Ab896AC07DED0D055e361f53174Bfe8ad": 1,
    "0xeEF5465a37D62CbCCdD21195d4cD7FA86784F296": 1,
    "0x6239247D1dF6Cb01D84282e6FAF1B3e4f4BFB6f3": 1,
    "0x1A09677b4831e255eC215eF399B0feBbdF57Df90": 1,
    "0x8aD605C554FE73CF572aB3ce27d973c221754057": 1,
    "0x0DE1cd5BDF4C64344F12a41D9F874017Dea06562": 1,
    "0x5B56Ac4AF83321664d12839Fa0b726FdFE6436F7": 1,
    "0x1a729a98D14dfDAe60b0A3925BfB13A574c73204": 1,
    "0xf1faECa73C28bd888BdA15D32181C6421D75B986": 1,
    "0xb567c9050E5b903d19c61Af60dB7698a24efA7B9": 1,
    "0x80256731f7aE1b5bA648a339E1e1417f110ab323": 1,
    "0x5677B247f9E3934F700d6F675a1723814dFb4903": 1,
    "0x3e7dC5c9D4d919c56b14f337514743E85A71aD1a": 1,
    "0xf77ffFac207A8e2eb7eDBe57570a364349FcEc99": 1,
    "0x769fa827A0645C4B3F6cD35b6713aa7cB369E5A3": 1,
    "0xFC3E6Cd7d1cEd01c253022a6C06A43733D55D1F7": 1,
    "0xD0580a5C08c2f8A3024178319CeC9EEdf39B618a": 1,
    "0x6831aEE4258F1AeaaD6Df29874BD5e1A5e39Dfab": 1,
    "0xF32c13c8F1a7254e987CdAF330Bbdb1500aA1abF": 1,
    "0x40F37C6e4710110a8D6622014E3Dbf63556aef17": 1,
    "0x16b0d1368146939e5C8F8E574F09f175baeC5996": 1,
    "0x06f97f429FF6c2c1284e7000470FbecdB0D52A05": 1,
    "0xf0F96fA6954D3cEd89CA2eE49b0b92F4f03ee5D7": 1,
    "0xDF9f2b6ff15Cca5d9D4F1B654ce7422830a8bC84": 1,
    "0x2f81Af83728F627DdC7Ef699D043AeB21f11d877": 1,
    "0xDA582DBb27CB5Cab9c7e65AA91962Ad57c735748": 1,
    "0xEc06E24b7BAbb16EDdb800060CD35925179C92B5": 1,
    "0xAc2A077a4BB1Cb85e76639ecbEb789b7BAF47969": 1,
    "0xb50fE6bdd3A14D5260De6cC5D821774eCfA52d1E": 1,
    "0x5C9A807593189Ce44f270d7B773bc19970cf388b": 1,
    "0x1E47Bc8b6c585DffF2E166257767050666151F0a": 1,
    "0x929C7a7F5617Bb9a4DaCC275F2008A302eD4a400": 1,
    "0x638cCd7E496b2828359F06FE15A67672506b0eb8": 1,
    "0x48143846EE5498fcB2228Ed1B7102BB2FE1ddCd4": 1,
    "0xe09283B2bE9431B6c9d866Ce1e1317F435d073e9": 1,
    "0x0ab00Dfe0f93D04D59BcB684Df441C45DC8CD821": 1,
    "0x10ef4A6f09757Fb95136C96a76C10fB49464FD65": 1,
    "0xE2288D9025D6AB184781cCa4cDb21Ca43F2C89e1": 1,
    "0x3b4FeeF2B444A3Fe4540c7956f74768738DA10f4": 1,
    "0x694961BCaE7990fEE772f5391Fd4639FC738f013": 1,
    "0x83F2BFE9E22625b02506e2d284ab3adCa8308687": 1,
    "0x79e0398F550cabb23a9043C83aaa4e6a524D480E": 1,
    "0x97064190190D4E2fB284a2d55DD53504e291C4CD": 1,
    "0x603ADe65B7FeC7C9275b599c546f8f1dd3aD21C5": 1,
    "0xaA34dde115E593dd26d0E6B250321803371Dfc95": 1,
    "0x999F5b05702532FefA42E28E260e54A09ebBb287": 1,
    "0x11d897F2C6D042Df82DA2644901169265208aFc3": 1,
    "0x55508F7d58736B363926fD6f330aF23034De5c7C": 1,
    "0x3FF817113ce8794b650F676BFc89D90fb699362D": 1,
    "0xd583B39f851808Cc29980932040985e0Af7d3A56": 1,
    "0x602BCaACe21dC369833C57331BcFe53B53677CAC": 1,
    "0xD53864866c922d8e81aa951D36265Bf45b2385A4": 1,
    "0x483B71648452CDbAeCc5965be0b837A0c0AFAdF3": 1,
    "0xda527c4164Ce69523A5E5F92Ff36D3d419727230": 1,
    "0x75acA769c4A4479174b0679B1D95aA4d97662A23": 1,
    "0x70Ca4BEFb5F6da9c5A741F4520cDF1a3eB2eaC7d": 1,
    "0xbf5dBe8fD00968334E5675C5F0161B2D35a9449B": 1,
    "0x5C563A17Ee800C88746795e39F2a26A72406B7A1": 1,
    "0x6ab30e00A8e0A01DACe2bE30c15e43C1c6A369a3": 1,
    "0x868F31C72a75deC3712f3b832D5d72E10aF642B7": 1,
    "0x67E0C5bFef5c819962157753b64F9eEc94c4B960": 1,
    "0xAc832857aCE7BBE40D94987c76F985f8D6Fa0038": 1,
    "0x6E4522cB25ecA759Ec387d027C52BDEc1b2c2bB2": 1,
    "0xfEe5D0103f41E07D6EC65767631e1f6F77D0D96d": 1,
    "0x6cC581A3251bDa010Bae782ae7056b0645c94B1E": 1,
    "0xC3580D20B7577e7388eFF874ab203acd6FfFDB30": 1,
    "0xa86B1ab5bc759FB0B7fc8611e1705688b747f487": 1,
    "0x1D1506140EA1832f4E57E3c0EcC78cD2f62b9986": 1,
    "0xB893AE8A1824604F6df4Dfde52E2754921ba1A73": 1,
    "0x3C682289EAF0f98Bbde84A4203E4dDA2994C2438": 1,
    "0xB0D1096A1DFF6c415a07A239D9749aCc0401Cd14": 1,
    "0xF367236D56338AC780267AC09217B02746607caE": 1,
    "0x157d605D32109983D2d4D901CBDDD885Fd450229": 1,
    "0xAad0ac2Ef7EC521d0990cA83586239Dd5e915688": 1,
    "0xCE2e67709418B452d8fCd36a502AF27BDE7d4D56": 1,
    "0xe66Ade24d99E76ad2e5dD28F3D9C34aB3A78ce22": 1,
    "0x6010ABE29A59FA938e8f71814fc91A6cD37C18EC": 1,
    "0x76667D3d8eC44297FA0989F80F67DabD3486a2D0": 1,
    "0x4E4343393471a23AA507914254827a835180EE10": 1,
    "0xFa954168A2833E7c9110eC90cDfe131D1F54888d": 1,
    "0xAeE8212c786C724D5682735D906a7b1E459fEF3f": 1,
    "0x1f982284fAa5b1411e79da210dB6b01eEFb74188": 1,
    "0x20c849199A06c654074A3d3308A4EDE7E4e8A6f2": 1,
    "0x223E5FCeA22a600561A5FD7AcB86b8CD0BE11c40": 1,
    "0xc58326c7020F26345f4568CC09DaDDf019a8e6D4": 1,
    "0x97e36e9Bd419B19b1969cd877098a93BFf5F0525": 1,
    "0x1795A964BeFF973E3c594f3C6Ae1d0Acf4dB6B27": 1,
    "0x5dE221D326D3aC8Ea255482c2B4B49aeFB8Fd050": 1,
    "0xF8DB01F59d0cAa15067156fF7Ed786EAF207753e": 1,
    "0xb75de461c1c0dC67a4FF1A140752dd2D01246d00": 1,
    "0xDADa245b9C6BBF1090B755449aF1E3eD06Ad7008": 1,
    "0xcAAAaB36db38fE0F3A3693f2600d267c3A8a106B": 1,
    "0xA11D442E1cF701A27431F695ddB95E21288CC7B3": 1,
    "0xE3820D1102bD78442f9617c1A7AB3fFB775b0cAB": 1,
    "0x7cd152502A15a957E869460a4Ea28a56f6681f55": 1,
    "0xC1EA2b9367D451E2dd7f667f3896bF1db08B3365": 1,
    "0x566f827A4988d4A3eb9DA469d8d3D0b536dA196e": 1,
    "0xc25dEb47F1b1c627A41a3Dc7325EB44646279Ae2": 1,
    "0x9349596C250D13897577728D933F153366bf1D13": 1,
    "0x41e99C80Cc42D9810fbf7FD207EfF6A2B11cbdd9": 1,
    "0x69147b4C6c18A3323f4B7a1bc8F003847Ab4891A": 1,
    "0x43Ec5640E18F7384761d8817AA55d38C9a03D855": 1,
    "0xEB1473264d03C667703C21bc83c0e7405768933e": 1,
    "0xA66fB92D138791351739f0a916C91Cd0c5063d59": 1,
    "0x1bE2e5c277f77679888B11c9311680fe873d3a3b": 1,
    "0x0Db597f7EcBf7CB8047402E1f7100818ad15D37d": 1,
    "0x88B988be0Bb7780f727363f8a72fc6c08e4302fc": 1,
    "0x7f5BaC7603CE122514F1A0825f7085896308a653": 1,
    "0x8300f444462E55D11Dba5823a1584BaDfC293Fd9": 1,
    "0x0ddA3757234fb4a8903C1F97828aF03793F4fb14": 1,
    "0xB2050c4fc92170e1196D5437cA01F0774843034B": 1,
    "0x82c854504F266dAd4a7eA9E448Ca7B1572AebF1C": 1,
    "0xF670f4451Fbf4430E58a65119202C0773a60b24E": 1,
    "0x446D05b7cbb29dc09dC521C4329f623cBCF0E752": 1,
    "0x94FcF973691d60047B3a17ac76399C23dce7986a": 1,
    "0x73f600A35DF2b5b0dd4fe81137B00a3A284C933b": 1,
    "0x885D34354731Ab056d87E1403C4E70aF528abb16": 1,
    "0x149D8bA66beDEab927ac8A89b7c30265C9aD7661": 1,
    "0x95D7d7BfDC6893B9693776F6F862E19E60259dF6": 1,
    "0x2a3f3869fEB04446883EAFc567B58803099458bb": 1,
    "0x1391AF29B482e0c32b717852d2A0D554AeDDa9DB": 1,
    "0xc2D898cb5f97CaA9E38d0f3D0eBab8F69Ba520e2": 1,
    "0x317710D0a517Aaa1e4B060EF5A740a2773A5F7ba": 1,
    "0xb917c85E98A7d3C0d55Dfdbf820Bac80C18c97D9": 1,
    "0xb95A0B2d7772260af5e57De4dE1D78B0acFb4167": 1,
    "0xbD26C2dB39B4117273cc234f7B610BCDEDc4E3B8": 1,
    "0x167F2f9B94fd9ddbF645509C3eE766aA9d8A0143": 1,
    "0x36E109a23061B8Be8D8B4C062d900A91FB725800": 1,
    "0x1276fD6Dc9482ccd458B6d17a13911D92a02935e": 1,
    "0x8721aAe749FE9c38d21cBea34f997d1A216E6909": 1,
    "0x4aD14ef998B2dF9A24B4728478B96a6f65873Ec0": 1,
    "0x17cbD516166720b8c75fE75235Ed0218d9CFDFbe": 1,
    "0x9f4c9c18fcaF361663c414A4d93aAefB81b61A2c": 1,
    "0x7D2a5CCCfBE775f1b58E3e399935d73754432DAc": 1,
    "0xa07f2A3A8a6e7F9e14CC0EB5633E6795814d549f": 1,
    "0x53966F194Ed8E4069b248423FDC8FfF5Ec0ea361": 1,
    "0xEF63b7c1E6fE105E09d6BF6FF03e9C7D947D3d44": 1,
    "0x184b91342e3441D0E0bFe7038D31cfFFF3333a47": 1,
    "0x6044352EAbC89Cc6d08023Fc2e5010f4031Ef4A3": 1,
    "0x647eC2f2E369e041884BfE80f5Ec39875120AEC5": 1,
    "0x51552898dfFCEe4b29224Ce4fC85AED6826F2810": 1,
    "0xd2a08007eeeaf1f81eeF54Ba6A8c4Effa1e545C6": 1,
    "0xB09736d869F5a00fC1FC85E7AACb94AD3Ab383Ba": 1,
    "0x2289C8FED5Be4B1c4fCfD7a74Ad2afcDD277312f": 1,
    "0xa934AF382db4124d6343077D605734a6768D1bCD": 1,
    "0xE23eD2A7bb55349b3f9070B4230491eBED11C89f": 1,
    "0x9f70C9116314e0d82BBe8fc4Ce6e600dc5149d68": 1,
    "0xbA966690326f93bb2353aFB327AfBa021605209A": 1,
    "0xAea9705d098685010e05B51aeF8948c654Ea923a": 1,
    "0xAAc762b187be05eE4eF0AfC5EF342be6d63BbaCC": 1,
    "0x56383A5Dd01d413fb8B38F2D0FceF5F511F1Dbb9": 1,
    "0x9A6835Fa450c11b1D0fFD3C958bfbD8a78Ca42B9": 1,
    "0xe0bc68Cd092D6d42a591b58D52294F8512BA703B": 1,
    "0x010a23A0308a22103E0CF5b27843fCaF9dCE9a88": 1,
    "0x15b25D425645A453E865352E60C96b1Feda23755": 1,
    "0xC3bB558C3df1b1F2222fe14b7807E5B857c5d328": 1,
    "0x551E9B974125aA2c36b78242254b4150caA80cA6": 1,
    "0xf7B5D543A6464C21679537c02e7a8C787314adD6": 1,
    "0xf72D8E520eC858Faf20912171aE868f46AD30a5F": 1,
    "0x3099E0856a277535627D5C6ab38Bf45169c567f9": 1,
    "0x4B93f138D48Ba77CEEA405689e18F490B029bb3B": 1,
    "0x6D67f6De91003fe86b858A571Dd7204a89A9fC65": 1,
    "0x075d846f46CA9A80491FD49AC31dF27c73af3FDA": 1,
    "0x7ED38B8338652856fD4C70C46e782FFF2eFBe7D4": 1,
    "0xf8A839F8c05094067C07DddAF95c75e38C2312B9": 1,
    "0xfd2c90dBA0BD1CAD3Eb8696A49e86d9d7Bf6A677": 1,
    "0x14Ddb398ce7A516c399a95B5d5C9A41326EC93dE": 1,
    "0x16278aE3bB0eEB3D04093a16C1290Ea8E2d9E879": 1,
    "0xFaFc5c6A95BD694f3C196dC3f7Eea21a8ca9da8A": 1,
    "0xfe63aF69c209016d470A73F308e5Ef8F3A4688d1": 1,
    "0xD563B90AeEe5a8aC590a16B0cbD88983E3edc7DE": 1,
    "0xfbeB39129B1e10985CE60dc4fa334303833f4CFd": 1,
    "0x7c0cCe553aE8616163db1b85D66CfA6aBd85e7CE": 1,
    "0xC22CD597B25C933366364F48Bb6C859f7FA781AB": 1,
    "0x92AB3806FB09a56b3090BC2c2Ae00EBaB4620D79": 1,
    "0xAaC7D3Ef895Aa709526B58D0933bf83548F06cb1": 1,
    "0x59d2e3449539679916230bE50A2ee50287DbBC27": 2,
    "0xc3F4728F673181e20862D21d028CFEaAcfB6d409": 2,
    "0xd461c0E84C98650B7d573Cb7cDd3d7E0bA402E6b": 2,
    "0x5115c67F5621440FD8413cdAD36FDf89b8AC4C01": 2,
    "0xfE0174255E410DefAaF58b06E009e0EbcD74db59": 2,
    "0xDdb92a96054132801CAE3943B2F966Fc2c308AC8": 2,
    "0xF0985CEBE3637B0E96F0F8dcAAD30574468B052d": 2,
    "0x32606c4aA95eA78D7c5b1e0E347E3E9ae451434e": 2,
    "0x66Af4d4f0a18E95a804CFC3852ff6F54d8BF2867": 2,
    "0x716eb921F3B346d2C5749B5380dC740d359055D7": 2,
    "0x727bcce16eb210Dad0A595C02C7782Be313105Ad": 2,
    "0x8914c82505BeC98AB6198800fa57677cb0ef4c2C": 2,
    "0x471ccd726115991cBac31C8f724Dd988Fe05a3aC": 2,
    "0xf5CBb183d0d883cE8B4a1964e4fF85b4d5ad4746": 2,
    "0x2c8875f34CeB219F61b7453b2C5f100eC2f6ED33": 2,
    "0x99F4f03F53dc5Cb4A1bb01EBBE29a5A4104C1Ead": 2,
    "0xf305F90B19CF66fC2D038f92a26440B66cF858F6": 2,
    "0x5F8ebac10CcF4E1B72e144eAbb752aD104a0eDaB": 2,
    "0xE2123387AF7c45C5B980F91a690222E9d7856Bb4": 2,
    "0x05C802C365601392867c340e492ef1d0DdAd7970": 2,
    "0x6de91f0606BCdc90b442F52DEd8a35bBE8C828d0": 2,
    "0xAAA8a090E78724c8E3f3dE407205fff160017568": 2,
    "0x3852471D266d9e2222CA9Fdd922BAFC904Dc49e5": 2,
    "0x41120313c3a305ac7D173EfC89Bd8A0Fc850f843": 2,
    "0x3E3567EB03521aeD1047951620CdeF6353F763bf": 2,
    "0xe703f231aB056eCb99C92a1232cC1020ACFC72f8": 2,
    "0xBE48873a51c40391d5aAae897E75211B0355fC46": 2,
    "0xfDeD90A3B1348425577688866f798f94d77A0D02": 2,
    "0x76d37D945582B028F8b8BC491931fe23019eE723": 2,
    "0x2F44Fb58135ae5d3793803C73D471C7CdE4BB774": 2,
    "0x1a3325C854B14935F5DEa94d687F8323e0f04E46": 2,
    "0x9C163eb3589B0DCf366fC23c581307618b68fb06": 2,
    "0xCBACd084174D920dFE0a0E46DC516bf116C7eB9a": 2,
    "0xF1213686b85B9c8b88fa1beEc543c21274333113": 2,
    "0xE16dF6503Acd3c79b6E032f62c61752bEC16eeF2": 2,
    "0x325c08A558ff02F244F31Ba1e87Bf3c1b1C08f45": 2,
    "0x6ed75E90DC27a1241335bBcAC03f052A4Bf590c0": 2,
    "0x30Eea1E603b556EEf164B4554bb6E7471B3e70f7": 2,
    "0x8D1919f9F69911b0c6630703F1aB5DA3d3FAF1CA": 2,
    "0xb751A112d92e279CEDaf7356D1C18b4BE231893A": 2,
    "0x9994592b5b57FFad91C96B9735078772F586c732": 2,
    "0xE188b0Ba723260B14D5C61DcF8e7a69Ab4B9cF41": 2,
    "0x74FDEA2eCEe55a47D292BF554666e672de06e3fC": 2,
    "0x333Ce0e5db6f06E1CE741b77E7cA4084fcecc37F": 2,
    "0x732739De18DF630b354EC365a927a4f4F2BF28E5": 2,
    "0xb7b70c2be01400eb89c9f7cfF667Ae5685AeDE71": 2,
    "0xA951294e2ff9D9796517B85Be85483CE608a9f02": 2,
    "0x859183F384Fe13b48Bc0529162cC98F17cE6e5cD": 2,
    "0xBDCa0A84C5c9f67cDCC615e60221c088971620e4": 2,
    "0x20b8Cd1C4AD5Eb94D180789D607F786FD783b78b": 2,
    "0xD29992887C049c061661fA0Ec6D9c3FBBef16974": 2,
    "0x7F86E7f867263590DF0B1c68FAAfD2cD8e108077": 2,
    "0xb0DB26154D1520894099123fA301322B93108c1D": 2,
    "0x798F73c7Df3932F5c429e618C03828627E51eD63": 2,
    "0xE052113bd7D7700d623414a0a4585BCaE754E9d5": 2,
    "0xaC4E3911656c2855b9b6362d92b243cB1842A3cc": 2,
    "0x2AD549B75C468525fdEfF99ce97f5cA4aD38a0b7": 2,
    "0x375c2B612795A462EbcBF6FfC4735E9D9B59E95D": 2,
    "0xe3E9858DD484A544d59187ac35175e9f560F1e9D": 2,
    "0xAc4d9ac4A74Df14868Ef42E0A873779f4146FB47": 2,
    "0xDA4B1D390F86fe8A6108c75432D0b71428d57bf7": 2,
    "0xC660de9c5291b0DCbE09b966fE34Cf710e6cC3a5": 2,
    "0x2c16bdaCF891061402ca95f54cB8Ec5C816c5648": 2,
    "0x85Aa8a3Dc39517Fbf7B17B6643C060437c0acCc6": 2,
    "0xE31278Bb6014Ee1fA9A47a417C73d64b7BB13572": 1,
    "0x798DB1De5a6989703186feb131E6E0DFfC0dF30d": 1,
    "0x0403542A67116b628fe61beb3acb9262935aF0a0": 1,
    "0x50deEFC1fd366D42846B1c0a23965Abc01261b38": 1,
    "0x581bD489306dF3fD5095b79d914D0db0F52eebD5": 1,
    "0x0D8005dc289094030b9C5F0d942e0fF6372F590F": 1,
    "0x2155DF680E53eB3d978158BbeB597e7615341AF7": 1,
    "0xbFD06c5485b837a620dF5E93CEb7b61B17cC2e04": 1,
    "0xA514b4552e4c78E08aC40Dcb4ed09B84d6612253": 1,
    "0xb8C99cf73B3fB4Fa362f44ee10fd8dAFaE45D7f0": 1,
    "0x79D72CA5c9aa9E16E0479940E467719C7E97f232": 1,
    "0x69C489719288655DedC602D7FCD345b937176e3d": 1,
    "0xA4c8d9e4Ec5f2831701A81389465498B83f9457d": 2,
    "0xB43801305Ed4468118c101eaBb49803a36417F13": 1,
    "0x108289ECB4830E7DE08dC8f9E58526DDeccd2d32": 1,
    "0x328f23C340152edd00CAA6Bb61761736dCC4E44a": 1,
    "0x3942BbBe675258d7bfC52B7b17e16eC32d79BBFd": 2,
    "0xd7F5fB2deb6A4c77E753e19f93EE04c787f66BC6": 1,
    "0x7fE1533C2e9AaB11d0e5074274EdC53Eeff8d840": 1,
    "0xE20fb5ae209a1EAEBAf6CfB50be12e528B0E0d84": 1,
    "0x76689EC1706693aA232c54045Ee4cd9e8f130bDe": 2,
    "0xd5f61EDD89DE23b27306eD6754Bc8B4209335559": 1,
    "0x1AC8b81EF928f5f34bF0ac9E3821611300cF5624": 1,
    "0x6729133D981e533b0a35268Dd6DCF7f8Dd0D9B6f": 1,
    "0x4CcDAFDAdd4A3b2Ead1B744C07A9f5CCaCa8f771": 1,
    "0xCCd8550fdDEdEc66075f689464FdE8a38ebE257f": 1,
    "0x9a90b4ca0c5d986A6370c5C0d7985500F47C00fB": 1,
    "0xd99208a6b2c353b88db78f04642b47F9FA993337": 1,
    "0x2A91557B4A9A625E810bA7E48D23dF57147b2004": 1,
    "0xF035839DaaF652f03156B297a27EdA25a35e6316": 1,
    "0x29bD2D1BC9382aB20ee799B6a8beaF9dE1a8E929": 1,
    "0x21C9d81e12F5A7D254a3985D77230247681E5729": 1,
    "0x519BD9F9F4649a0f1623Bd0f8D45e029742b5FAa": 1,
    "0xDfd9A4d42b8C2D5ae88A9c8566d0D441A522e602": 1,
    "0xfDa8A4bB72c979722052ad81F5C8f454b2a2D20F": 1,
    "0x5D84F9c3ffDF3778d0A8273e048BED0476772Ef5": 1,
  },
};
