// Types
type IWhitelist = {
  whitelist: Record<string, number>;
};

// Config from generator
export const claimConfig: IWhitelist = {
  whitelist: {
    "0xB09bfe09d6AE5816Ca6d953c1bfBD529963d5aA1": 2,
    "0x2f44fb58135ae5d3793803c73d471c7cde4bb774": 2,
    "0x792b37c2244ecc0bc18ad193498f17b27efae7d9": 2,
    "0xe61121DD5692C97f02504d2c01BE88339a7a73c9": 2,
    "0x2c16bdaCF891061402ca95f54cB8Ec5C816c5648": 2,
    "0x3852471d266d9e2222ca9fdd922bafc904dc49e5": 2,
    "0x665654f2D2a151bE2D0F8E3697e6Ce780F732aF2": 2,
    "0x378BCce7235D53BBc3774BFf8559191F06E6818E": 2,
    "0xDD0C246165c8B4a90bC5A446DA9EE0908a45966F": 2,
    "0x1fdf89Dd0Eba85603CBdE7f9F5cE5D830ffc7643": 2,
    "0x059248De52D8aD545462059367DF7A3f91b40607": 2,
    "0x6e9fe041e0bA8C2Af35215d900D188d53d7A9B41": 2,
    "0xBaEa3Cf94aBd0D6E0F029ef5B0E54E9424A72985": 2,
    "0x1DA5331994e781AB0E2AF9f85bfce2037A514170": 2,
    "0x41A322b28D0fF354040e2CbC676F0320d8c8850d": 2,
    "0xC0B9c3C9890fF4B7B5264d36C911781890a9b32f": 2,
    "0xF9963dbe9438A5ECb62e5e7c2C081C3d12D48dd5": 2,
    "0x1010595F96Ab62b31BfeAc411Ec5f8f60DB5DC23": 1,
    "0x5f62831786a120a4475d97CA705Dd606296Fc04e": 1,
    "0xB065e10499857Fd6F0688FD96D1277F01B632E1C": 1,
    "0x633c09a9b40B6eea024F932c24459fb36991343b": 1,
    "0xa8Fd04B6D9Ee5ed09f3fd54E60aeA03A749F42cC": 1,
    "0x60af773d79cf28e3c45aaf8660c0b67b0b6c8ce4": 1,
    "0x0F9ef6dF697D63660D8a5AD2f29003633ead3D52": 1,
    "0xCD12D73c1473F63E5af0c609abF2113B1998055C": 1,
    "0x109848B8Ee215b452B45a271b941D2800096a18E": 1,
    "0xDcaE87821FA6CAEA05dBc2811126f4bc7fF73bd1": 1,
    "0xC264880faD20D56Eb6C5c18BcB2F3d50F72c7663": 1,
    "0x5ea9681C3Ab9B5739810F8b91aE65EC47de62119": 1,
    "0x8Dbbca57Ea56290Efa14D835bBfd34fAF1d89753": 1,
    "0x621Fa2A2Dff1012CE2989E583C407D060Ef59754": 1,
    "0x2c8875f34CeB219F61b7453b2C5f100eC2f6ED33": 2,
    "0xF0985CEBE3637B0E96F0F8dcAAD30574468B052d": 1,
    "0x41120313c3a305ac7D173EfC89Bd8A0Fc850f843": 1,
    "0x32606c4aA95eA78D7c5b1e0E347E3E9ae451434e": 1,
    "0xCBACd084174D920dFE0a0E46DC516bf116C7eB9a": 2,
    "0x2F44Fb58135ae5d3793803C73D471C7CdE4BB774": 1,
    "0xfE0174255E410DefAaF58b06E009e0EbcD74db59": 1,
    "0x9C163eb3589B0DCf366fC23c581307618b68fb06": 1,
    "0x5115c67F5621440FD8413cdAD36FDf89b8AC4C01": 1,
    "0x471ccd726115991cBac31C8f724Dd988Fe05a3aC": 1,
    "0x8914c82505BeC98AB6198800fa57677cb0ef4c2C": 1,
    "0x1a3325C854B14935F5DEa94d687F8323e0f04E46": 1,
    "0x05C802C365601392867c340e492ef1d0DdAd7970": 1,
    "0xE2123387AF7c45C5B980F91a690222E9d7856Bb4": 1,
    "0xc3F4728F673181e20862D21d028CFEaAcfB6d409": 2,
    "0x325c08A558ff02F244F31Ba1e87Bf3c1b1C08f45": 1,
    "0x6de91f0606BCdc90b442F52DEd8a35bBE8C828d0": 1,
    "0xfDeD90A3B1348425577688866f798f94d77A0D02": 1,
    "0x99F4f03F53dc5Cb4A1bb01EBBE29a5A4104C1Ead": 1,
    "0xd461c0E84C98650B7d573Cb7cDd3d7E0bA402E6b": 1,
    "0x3852471D266d9e2222CA9Fdd922BAFC904Dc49e5": 1,
    "0xe703f231aB056eCb99C92a1232cC1020ACFC72f8": 1,
    "0x3E3567EB03521aeD1047951620CdeF6353F763bf": 1,
    "0x59d2e3449539679916230bE50A2ee50287DbBC27": 1,
    "0x30Eea1E603b556EEf164B4554bb6E7471B3e70f7": 1,
    "0x727bcce16eb210Dad0A595C02C7782Be313105Ad": 1,
    "0xAAA8a090E78724c8E3f3dE407205fff160017568": 1,
    "0x8D1919f9F69911b0c6630703F1aB5DA3d3FAF1CA": 2,
    "0x5F8ebac10CcF4E1B72e144eAbb752aD104a0eDaB": 1,
    "0x66Af4d4f0a18E95a804CFC3852ff6F54d8BF2867": 1,
    "0xDdb92a96054132801CAE3943B2F966Fc2c308AC8": 1,
    "0x6ed75E90DC27a1241335bBcAC03f052A4Bf590c0": 1,
    "0xBE48873a51c40391d5aAae897E75211B0355fC46": 1,
    "0xE16dF6503Acd3c79b6E032f62c61752bEC16eeF2": 1,
    "0x76d37D945582B028F8b8BC491931fe23019eE723": 1,
    "0xF1213686b85B9c8b88fa1beEc543c21274333113": 2,
    "0xf305F90B19CF66fC2D038f92a26440B66cF858F6": 2,
    "0xf5CBb183d0d883cE8B4a1964e4fF85b4d5ad4746": 1,
    "0x2349783bf3e10b36e78e6cb9d9f417c964ed1d82": 1,
    "0x0a2542a170aA02B96B588aA3AF8B09AB22a9D7ac": 2,
    "0xddc93DBB6889e85ee9a16a7DFeF8F20FA7453f61": 1,
    "0xd2a08007eeeaf1f81eeF54Ba6A8c4Effa1e545C6": 2,
    "0x20096F4cED01dBbb6f338Cc5A8a47cF7ee662A0f": 1,
    "0x7C43a86A6D9e262451205f39E5E0fdaBA02789Cb": 1,
    "0x4b84864C085c27f50bDeA25671E045d6039bcC00": 2,
    "0xfECCbC981b3F13Fc5C4a96429772d2E424AdCd9f": 1,
    "0xE2D2e99fA28220c8bc9BC9d42B8D65f76D6F994D": 1,
  },
};
